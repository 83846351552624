import { Graph as GraphAPIType, Node, Relationship, chambertypes, countrytypes, importancetypes } from './api/APITypes';
import Graph from 'graphology';
import { GraphView } from './state/ViewTypes';
import { theme } from '@chakra-ui/theme';
import { v4 as uuidv4 } from 'uuid';

function graphFromData(props: { data: GraphAPIType; centerNode: string }): Graph {

  const graph = new Graph({ type: 'mixed' });
  let centerNode: Node | undefined = undefined;

  for (let i = 0; i < props.data.nodes.length; i++) {
    let node = props.data.nodes[i];
    if (node.properties.id == props.centerNode) centerNode = props.data.nodes[i];
    addNodeToGraph(node, graph, node.properties.id === props.centerNode);
  }
  for (let i = 0; i < props.data.edges.length; i++) {
    let edge = props.data.edges[i];
    addEdgeToGraph(edge, graph, centerNode!.elementId);
  }
  setNodeSizeBasedOnDegree(graph, useOutDegree);

  console.log(graph.getNodeAttributes(graph.nodes()[0]))
  return graph;
}

function addNodeToGraph(node: Node, graph: Graph, isCenter?: boolean) {
  let position: { x: number; y: number } = findValidPosition(graph, node);
  
  if (isCenter) console.log("center", node)

  graph.addNode(node.elementId, {
    label: node.properties.label?.replace('CASE OF', ''),
    id: node.properties.id,
    judgementdate: node.properties.judgementdate,
    nodeType: node.labels[0],
    importance: node.properties.importance,
    country: node.properties.country,
    conclusion: node.properties.conclusion,
    chambertype: node.properties.chambertype,
    separateopinion: node.properties.separateopinion,
    keywords: node.properties.keywords,
    x: position.x,
    y: position.y, //Math.random() * 200000000000 - 200000000000 / 2,
    color: isCenter ? theme.colors.purple[400] : getNodeColor(node.properties.importance!.toString()),
    originalColor: isCenter ? theme.colors.purple[400] : getNodeColor(node.properties.importance!.toString()),
    size: 5, //sets the initial size which is updated later
    type: "border",
    originalBorderColor: getNodeBorderColor(node.properties.violation ?? "", node.properties.nonviolation ?? ""),
    borderColor: getNodeBorderColor(node.properties.violation ?? "", node.properties.nonviolation ?? ""),
    borderSize: 1,
    violation: node.properties.violation,
    nonviolation: node.properties.nonviolation,
    url: node.properties.url,
  });
}

function addEdgeToGraph(edge: Relationship, graph: Graph, centerNode: string) {
  if (!(edge.startNodeElementId == centerNode) && !(edge.endNodeElementId == centerNode)) {
    graph.addDirectedEdgeWithKey(edge.elementId, edge.startNodeElementId, edge.endNodeElementId, { label: edge.type, size: 1, hidden: true });
  } else {
      graph.addDirectedEdgeWithKey(edge.elementId, edge.startNodeElementId, edge.endNodeElementId, { label: edge.type, size: 1 });

  }
}

function getDefaultGraphView(centerNode: string): GraphView {
  let graphView: GraphView = {
    name: 'Graph',
    id: uuidv4(),
    centerNode: centerNode,
    floatingDocuments: [],
    filter: {
      decisionLevel: chambertypes,
      importance: importancetypes,
      timeFilter: { lower: -946774800000, upper: new Date().getTime() },
      countrys: countrytypes,
    },
  };
  return graphView;
}


function findValidPosition(graph: Graph, inputNode: Node): { x: number; y: number } {
  console.log("find valid position is running")
  let position = { x: new Date(inputNode.properties.judgementdate!).getTime(), y: Math.random() * 200000000000 - 200000000000 / 2 };

  const maxSize = 10000000000;

  if (graph.nodes().length == 0) return position;
  let overlap = true;

  while (overlap) {
    overlap = false; // Assume no overlap unless we find one

  graph.forEachNode((existingNode) => {
    const leftBoundExistingNode = graph.getNodeAttribute(existingNode, 'x') - maxSize;
    const rightBoundExistingNode = graph.getNodeAttribute(existingNode, 'x') + maxSize;

    const leftBoundInputNode = position.x - maxSize; 
    const rightBoundInputNode = position.x + maxSize;

    const topBoundExistingNode = graph.getNodeAttribute(existingNode, 'y') + maxSize;
    const bottomBoundExistingNode = graph.getNodeAttribute(existingNode, 'y') - maxSize;

    const topBoundInputNode = position.y + maxSize;
    const bottomBoundInputNode = position.y - maxSize;

    if ((leftBoundExistingNode <= rightBoundInputNode && rightBoundInputNode <= rightBoundExistingNode) || (rightBoundExistingNode >= leftBoundInputNode && leftBoundInputNode >= leftBoundExistingNode)) {
      if ((topBoundExistingNode >= bottomBoundInputNode && bottomBoundInputNode >= bottomBoundExistingNode) || (bottomBoundExistingNode <= topBoundInputNode && topBoundInputNode <= topBoundExistingNode)) {
        position.y += 1000000000;
        overlap=true
      }
    }
  });
}
  return position;
}

function setNodeSizeBasedOnDegree(graph: Graph, useOutDegree: boolean) { //also updates when you expand neighbours
  //todo: update when reccomended neighbours (undirected edge?)
  
  graph.forEachNode((node) => {
    const degree = useOutDegree ? graph.outDegree(node) : graph.inDegree(node);
    let nodeSize = 5;
    if (degree < 5) {
      nodeSize = 5;
    } else if (degree > 20) {
      nodeSize = 20;
    } else {
      nodeSize = degree;
    }
    graph.setNodeAttribute(node, 'size', nodeSize);
  });
}

let useOutDegree = true;


//todo: put in filteractions and use this later to switch between in and out degree to determine size
function toggleDegree(graph: Graph) {
  useOutDegree = !useOutDegree;
  setNodeSizeBasedOnDegree(graph, useOutDegree);
}

function getNodeColor(type: string) {
  switch (type) {
    case 'Keyword':
      return '#fff873';
    case 'Article':
      return '#065666';
    case 'Paragraph':
      return '#ff6e6e';
    case 'SubParagraph':
      return '#ffbfbf';
    case 'Document':
      return '#6B46C1';
    case '0':
      return theme.colors.green[400];
    case '1':
      return theme.colors.blue[900];
    case '2':
      return theme.colors.blue[700];
    case '3':
      return theme.colors.blue[400];
    case '4':
      return theme.colors.blue[200];
    default:
      return '#697aff';
  }
}


function getNodeBorderColor(violation: string, nonviolation: string) {
  
  if (violation && nonviolation) {
    return theme.colors.orange[400];
  } else if (violation) {
    return theme.colors.red[600];
  } else if (nonviolation) {
    return theme.colors.green[600];
  }
  return theme.colors.blue[500];
}


export { graphFromData, getNodeColor, getNodeBorderColor, addNodeToGraph, addEdgeToGraph, getDefaultGraphView, setNodeSizeBasedOnDegree };
