import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import View, { Filter, Folder, GraphView } from '../ViewTypes';
import { Chambertype, Country, Importancetype, Node } from '../../api/APITypes';
const initial: View = {
  id: uuidv4(),
  name: 'View 1',
  folders: [{ id: 'default', name: 'Default', documents: [] }],
  graphViews: {},
  openDocument: undefined,
  search: [],
  refreshCounter: 0,
  toolOpenStatus: {
    savedCases: true,
    advancedFilters: false,
    search: false,
    timeFilter: true,
  },
};

export const viewSlice = createSlice({
  name: 'view',
  initialState: initial,
  reducers: {
    setView: (state, action: { payload: { graphViews: { [id: string]: GraphView }; folders: Folder[] } }) => {
      state.graphViews = action.payload.graphViews;
      state.folders = action.payload.folders;
    },
    addFolder: (state, action: { payload: string }) => {
      state.folders.push({
        id: uuidv4(),
        name: action.payload,
        documents: [],
      });
    },
    updateFolder: (state, action: { payload: Folder }) => {
      let folder_index = state.folders.findIndex((folder) => folder.id === action.payload.id);
      state.folders[folder_index] = action.payload;
    },
    saveDoc: (state, action: { payload: { folderId: string; nodeId: string } }) => {
      let folder = state.folders.find((folder) => folder.id === action.payload.folderId);
      if (!folder) return;
      for (const docid of folder.documents) {
        if (docid === action.payload.nodeId) {
          return;
        }
      }
      folder.documents.push(action.payload.nodeId);
    },
    removeDoc: (state, action: { payload: { folderId: string; nodeId: string } }) => {
      let folderIndex = state.folders.findIndex((folder) => folder.id === action.payload.folderId);
      if (folderIndex < 0) return;
      const index = state.folders[folderIndex].documents.indexOf(action.payload.nodeId);
      if (index > -1) state.folders[folderIndex].documents.splice(index, 1); // 2nd parameter means remove one item only
    },
    addFloatingDoc: (
      state,
      action: {
        payload: {
          graphViewId: string;
          nodeId: string;
          id: string;
        };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      let floatingDocumentIndex = graphView.floatingDocuments.findIndex(
        (doc) => doc.nodeId === action.payload.nodeId,
      );
      if (floatingDocumentIndex === -1)
        graphView.floatingDocuments.push({
          nodeId: action.payload.nodeId,
          id: action.payload.id,
        });
    },

    removeFloatingDoc: (
      state,
      action: { payload: { graphViewId: string; floatingDocId: string } },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId]; 

      let floatingDocumentIndex = graphView.floatingDocuments.findIndex(
        (doc) => doc.id === action.payload.floatingDocId,
      );
      if (floatingDocumentIndex > -1) graphView.floatingDocuments.splice(floatingDocumentIndex, 1);
    },
    openDocument: (state, action: { payload: string }) => {
      state.openDocument = action.payload;
    },
    closeDocument: (state) => {
      state.openDocument = undefined;
    },
    setCenterNode: (state, action: { payload: { graphViewId: string; centerNode: string } }) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      graphView.centerNode = action.payload.centerNode;
    },
    setFilter: (state, action: { payload: { graphViewId: string; filter: Filter } }) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      graphView.filter = action.payload.filter;
    },
    setTimeFilter: (
      state,
      action: {
        payload: {
          graphViewId: string;
          timeFilter: {
            upper: number;
            lower: number;
          };
        };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      graphView.filter.timeFilter = action.payload.timeFilter;
    },
    addImportanceFilter: (
      state,
      action: {
        payload: { importance: Importancetype; graphViewId: string };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      if (!graphView.filter.importance.includes(action.payload.importance)) {
        graphView.filter.importance.push(action.payload.importance);
      }
    },
    removeImportanceFilter: (
      state,
      action: {
        payload: { importance: Importancetype; graphViewId: string };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      let importance_index = graphView.filter.importance.findIndex(
        (value) => value === action.payload.importance,
      );
      if (importance_index > -1) {
        graphView.filter.importance.splice(importance_index, 1);
      }
    },
    addDecisionLevelFilter: (
      state,
      action: {
        payload: { decisionLevel: Chambertype; graphViewId: string };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      if (!graphView.filter.decisionLevel.includes(action.payload.decisionLevel)) {
        graphView.filter.decisionLevel.push(action.payload.decisionLevel);
      }
    },
    removeDecisionLevelFilter: (
      state,
      action: {
        payload: { decisionLevel: Chambertype; graphViewId: string };
      },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      let decision_level_index = graphView.filter.decisionLevel.findIndex(
        (value) => value === action.payload.decisionLevel,
      );
      if (decision_level_index > -1) {
        graphView.filter.decisionLevel.splice(decision_level_index, 1);
      }
    },
    addCountryFilter: (
      state,
      action: { payload: { country: Country; graphViewId: string } },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      if (!graphView.filter.countrys.includes(action.payload.country)) {
        graphView.filter.countrys.push(action.payload.country);
      }
    },
    removeCountryFilter: (
      state,
      action: { payload: { country: Country; graphViewId: string } },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      let country_index = graphView.filter.countrys.findIndex(
        (value) => value === action.payload.country,
      );
      if (country_index > -1) {
        graphView.filter.countrys.splice(country_index, 1);
      }
    },
    setCountryFilter: (
      state,
      action: { payload: { countrys: Country[]; graphViewId: string } },
    ) => {
      let graphView = state.graphViews[action.payload.graphViewId];
      graphView.filter.countrys = action.payload.countrys;
    },
    addNewGraphView: (state, action: { payload: GraphView }) => {
      state.graphViews[action.payload.id] = action.payload;
    },
    updateGraphView: (
      state,
      action: { payload: { graphViewId: string; graphView: GraphView } },
    ) => {
      state.graphViews[action.payload.graphViewId] = action.payload.graphView;
    },
    search: (state, action: { payload: Node[] }) => {
      state.search = action.payload;
    },
    setToolOpenStatus: (
      state,
      action: {
        payload: {
          savedCases: boolean;
          advancedFilters: boolean;
          search: boolean;
          timeFilter: boolean;
        };
      },
    ) => {
      state.toolOpenStatus = action.payload;
    },
    addToSearch: (state, action) => {
      // If Node in payload is not present in search, add it
      if (
        state.search.findIndex((node) => node.properties.id === action.payload.properties.id) === -1
      ) {
        state.search.push(action.payload);
      }
    },
    incrementRefreshCounter: (state) => {
      state.refreshCounter++;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setView,
  saveDoc,
  addFloatingDoc,
  removeFloatingDoc,
  addFolder,
  removeDoc,
  openDocument,
  closeDocument,
  setCenterNode,
  setTimeFilter,
  addNewGraphView,
  addImportanceFilter,
  removeImportanceFilter,
  addDecisionLevelFilter,
  removeDecisionLevelFilter,
  addCountryFilter,
  removeCountryFilter,
  setCountryFilter,
  setFilter,
  search,
  updateFolder,
  updateGraphView,
  setToolOpenStatus,
  addToSearch,
  incrementRefreshCounter,
} = viewSlice.actions;

export default viewSlice.reducer;
