import { Button, Menu, MenuButton, MenuItem, MenuList, Stack, useToast } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../lib/hooks';
import { GraphView } from '../../../lib/state/ViewTypes';
import { getDefaultGraphView } from '../../../lib/GraphFactory';
import { addNewGraphView, saveDoc } from '../../../lib/state/slices/ViewSlice';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useSigma } from '@react-sigma/core';
import { useEffect, useState } from 'react';

export default function NodeContextMenu(props: { id: string; nodeId: string, loadNeighbours: (id: string) => void; recommendNeighbours: (id: string, nodeId: string) => void }) {
  const dispatch = useAppDispatch();
  const folders = useAppSelector((state) => state.viewReducer.folders);
  const toast = useToast();

  let sigma = useSigma()
  const [position, setPosition] = useState(() => {
    let attributes = sigma.getGraph().getNodeAttributes(props.nodeId)
    let viewportPosition = sigma.graphToViewport({ x: attributes.x, y: attributes.y })
    viewportPosition.x = viewportPosition.x - 200
    viewportPosition.y = viewportPosition.y + 10
    return viewportPosition
  })
  useEffect(() => {
    sigma.on('afterRender', () => {
      let attributes = sigma.getGraph().getNodeAttributes(props.nodeId)
      let viewportPosition = sigma.graphToViewport({ x: attributes.x, y: attributes.y })
      viewportPosition.x = viewportPosition.x - 200
      viewportPosition.y = viewportPosition.y + 10
      setPosition(viewportPosition)
    })

  }, [props.nodeId])

  return (
    <div
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        backgroundColor: '#f0f0f0f0',
        padding: '3px',
        borderRadius: '3px',
        zIndex: 1000,
      }}
    >
      <Stack>
        <Button
          onClick={() => {
            let newGraphView = getDefaultGraphView(props.id);
            dispatch(addNewGraphView(newGraphView));
            toast({
              title: 'Graph opened',
              description: 'Your Graph has been opened in a new tab',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}
        >
          New Center
        </Button>
        <Button
          onClick={() => {
            props.loadNeighbours(props.id);
            toast({
              title: 'Neigbours loaded',
              description: 'The new neighbours might not be visible due to filters',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}
        >
          Expand Neighbours
        </Button>
        {/* <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Save to Folder
          </MenuButton>
          <MenuList>
            {folders.map((folder) => (
              <MenuItem
                key={folder.id}
                onClick={() => {
                  dispatch(saveDoc({ folderId: folder.id, nodeId: props.id }));
                  toast({
                    title: 'Document saved',
                    description: 'The document has been saved to ' + folder.name,
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                  });
                }}
              >
                {folder.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu> */}
        <Button
          onClick={() => {
            dispatch(saveDoc({ folderId: 'default', nodeId: props.id }));
            toast({
              title: 'Document saved',
              description: 'The document has been saved',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}

        >
          Save Case
        </Button>
        <Button
          onClick={() => {
            props.recommendNeighbours(props.id, props.nodeId);
            toast({
              title: 'Neigbours recommended',
              description: 'The new neighbours might not be visible due to filters',
              status: 'info',
              duration: 3000,
              isClosable: true,
            });
          }}
        >
          Recommend Neighbours
        </Button>
      </Stack>
    </div>
  );
}
