import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, Stack, Text, theme } from '@chakra-ui/react';
import { CSSProperties, useContext } from 'react';
import { useAppDispatch, useAppSelector } from '../../../lib/hooks';
import { ImportanceNames, chambertypes, countrytypes, importancetypes } from '../../../lib/api/APITypes';
import { getDefaultGraphView } from '../../../lib/GraphFactory';
import { addCountryFilter, addDecisionLevelFilter, addImportanceFilter, removeCountryFilter, removeDecisionLevelFilter, removeImportanceFilter, setCountryFilter, setFilter, setTimeFilter } from '../../../lib/state/slices/ViewSlice';
import { motion } from 'framer-motion';
import { GraphViewContext } from '../Index';

export default function Filter(props: { style?: CSSProperties }) {
  const dispatch = useAppDispatch();
  const graphView = useContext(GraphViewContext);
  let importanceFilter = useAppSelector((state) => state.viewReducer.graphViews[graphView.id].filter.importance);
  let decisionLevelFilter = useAppSelector((state) => state.viewReducer.graphViews[graphView.id].filter.decisionLevel);
  let countryFilter = useAppSelector((state) => state.viewReducer.graphViews[graphView.id].filter.countrys);
  const open = useAppSelector((state) => state.viewReducer.toolOpenStatus.advancedFilters);

  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0, display: 'none' },
  };
  return (
    <motion.div variants={variants} animate={open ? 'open' : 'closed'}>
      <div
        style={{
          ...props.style,
          display: 'flex',
          flexDirection: 'column',
          width: '300px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: '10px',
          zIndex: '100',
        }}
      >
        <Accordion allowMultiple width="100%" borderColor={theme.colors.gray[400]}>
          <AccordionItem style={{ borderBottomWidth: '0px' }}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <Text as="b">Filter</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Accordion allowMultiple width="100%" borderColor={theme.colors.purple[500]}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                          }}
                        >
                          <Text as="b">Country</Text>{' '}
                          <Text as="b" color={theme.colors.purple[500]} marginLeft="5px">
                            ({countryFilter.length})
                          </Text>
                        </div>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} height="300px" overflowY="scroll">
                      <Stack>
                        {countrytypes.map((value) => {
                          return (
                            <Checkbox
                              key={value}
                              colorScheme="purple"
                              isChecked={countryFilter.includes(value)}
                              onContextMenu={(event) => {
                                dispatch(
                                  setCountryFilter({
                                    graphViewId: graphView.id,
                                    countrys: [value],
                                  }),
                                );
                                event.preventDefault();
                              }}
                              onChange={(event) => {
                                if (!countryFilter.includes(value)) {
                                  dispatch(
                                    addCountryFilter({
                                      graphViewId: graphView.id,
                                      country: value,
                                    }),
                                  );
                                } else {
                                  dispatch(
                                    removeCountryFilter({
                                      graphViewId: graphView.id,
                                      country: value,
                                    }),
                                  );
                                }
                              }}
                            >
                              {value}
                            </Checkbox>
                          );
                        })}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                          }}
                        >
                          <Text as="b">Decision Level</Text>{' '}
                          <Text as="b" color={theme.colors.purple[500]} marginLeft="5px">
                            ({decisionLevelFilter.length})
                          </Text>
                        </div>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Stack>
                        {chambertypes.map((value) => {
                          return (
                            <Checkbox
                              key={value}
                              colorScheme="purple"
                              isChecked={decisionLevelFilter.includes(value)}
                              onChange={() => {
                                if (!decisionLevelFilter.includes(value)) {
                                  dispatch(addDecisionLevelFilter({ graphViewId: graphView.id, decisionLevel: value }));
                                } else {
                                  dispatch(removeDecisionLevelFilter({ graphViewId: graphView.id, decisionLevel: value }));
                                }
                              }}
                            >
                              {value}
                            </Checkbox>
                          );
                        })}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                          }}
                        >
                          <Text as="b">Importance</Text>{' '}
                          <Text as="b" color={theme.colors.purple[500]} marginLeft="5px">
                            ({importanceFilter.length})
                          </Text>
                        </div>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Stack>
                        {importancetypes.map((value) => {
                          return (
                            <Checkbox
                              key={value}
                              colorScheme="purple"
                              isChecked={importanceFilter.includes(value)}
                              onChange={(event) => {
                                if (!importanceFilter.includes(value)) {
                                  dispatch(
                                    addImportanceFilter({
                                      graphViewId: graphView.id,
                                      importance: value,
                                    }),
                                  );
                                } else {
                                  dispatch(removeImportanceFilter({ graphViewId: graphView.id, importance: value }));
                                }
                              }}
                            >
                              {ImportanceNames[value]}
                            </Checkbox>
                          );
                        })}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-around',
                    marginTop: 20,
                  }}
                >
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={() => {
                      dispatch(
                        setFilter({
                          graphViewId: graphView.id,
                          filter: {
                            decisionLevel: [],
                            importance: [],
                            timeFilter: { lower: 0, upper: new Date().getTime() },
                            countrys: [],
                          },
                        }),
                      );
                    }}
                  >
                    Deselect All
                  </Button>
                  <Button
                    variant="solid"
                    colorScheme="purple"
                    onClick={() => {
                      dispatch(
                        setFilter({
                          graphViewId: graphView.id,
                          filter: getDefaultGraphView('undefined').filter,
                        }),
                      );
                    }}
                  >
                    Select All
                  </Button>
                </div>
              </div>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </motion.div>
  );
}
