import { useDispatch, useSelector, useStore } from 'react-redux';
import type { AppDispatch, RootState } from './state/Store';
import { createSelector } from '@reduxjs/toolkit';
import ViewSlice from './state/slices/ViewSlice';
import { GraphView } from './state/ViewTypes';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const selectGraphView = createSelector(
    [
        state => state.view.graphViews,
        (state, graphViewId) => graphViewId
    ], (graphViews, graphViewId) => graphViews.find((graphView: GraphView) => graphView.id === graphViewId)
)